import { useState } from "react";
import ChartLine from "./ChartLine";
import LeftMenu from "./LeftMenu";
import { useLocation } from "react-router-dom";

const Chart = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const sensor = searchParams.get("sensor");
  const type = searchParams.get("type");

  const [selectedHours, SetSelectedHours] = useState("24");
  const [usedHours, SetUsedHours] = useState("24");

  const handleInputChange = (event) => {
    let newValue = parseInt(event.target.value);
    // Sprawdź, czy wprowadzona wartość jest większa niż 1500
    if (newValue > 1500) {
      newValue = 1500; // Jeśli tak, ustaw wartość na 1500
    }
    SetSelectedHours(newValue);
  };
  const handleClick = () => {
    SetUsedHours(selectedHours);
  };

  return (
    <div className="flex-wrap bg-slate-800 flex w-full text-zinc-100">
      <LeftMenu></LeftMenu>
      <div className="flex-auto md:w-10/12 pl-6 pt-4 sm:w-full min-h-screen  ">
        <ChartLine type={type} sensor={sensor} hour={usedHours}></ChartLine>

        <div className="flex flex-wrap">
          <h1 className="p-0 text-1xl font-extrabold text-white md:text-1xl lg:text-4xl mb-4 w-96 mt-10 w-full">
            Wybierz ile godzin wstecz narysować wykres:
          </h1>
          <input
            className="h-fit lg:text-xl md:text-sm shadow appearance-none hover:bg-slate-950 rounded py-2 px-3 text-zinc-100 siez leading-tight focus:outline-none focus:shadow-outline bg-slate-900"
            data-input-counter-min="15"
            data-input-counter-max="30"
            max={1500}
            min={1}
            type="number"
            value={selectedHours}
            onChange={handleInputChange}
            placeholder="Enter a number"
          />
          <button
            class="bg-slate-900 hover:bg-slate-950 text-zinc-100 font-semibold py-2 px-4 border border-slate-800 rounded shadow ml-5"
            onClick={handleClick}
          >
            Pokaż
          </button>
        </div>
      </div>
    </div>
  );
};

export default Chart;
