import { Link } from "react-router-dom";
const Navbar = () => {
  return (
    <div className="flex min-h-14 w-full bg-slate-950 items-center sm:justify-center xl:justify-start">
      <Link to="/" className="flex items-center">
        <h2 className="md:text-4xl sm:text-2x1 font-extrabold text-zinc-100 xl:p-5">
          Dane pogodowe PANS
        </h2>
      </Link>
    </div>
  );
};

export default Navbar;
