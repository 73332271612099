import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import URL from "../config";
import { toast } from "react-toastify";
const LeftMenu = () => {
  const [sensors, setSensors] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${URL}data/getSensors`);
        if (!response.ok) {
          throw new Error("coś poszło nie tak");
        }

        const sensors = await response.json();
        setSensors(sensors);
      } catch (error) {
        console.error("Problem z pobraniem danych: ", error);
        toast.error(`${error}`, { theme: "dark" });
      }
    };

    fetchData();
  }, []);

  return (
    <div className="flex-auto md:w-2/12 bg-slate-900 pt-5 pl-3 min-h-screen  sm:min-h-fit sm:w-full">
      <div>
        {sensors.HUMIDITY && (
          <>
            <h2 className="mb-2 text-1xl font-extrabold text-white md:text-1xl lg:text-1xl">
              <span className="text-transparent bg-clip-text bg-gradient-to-r to-emerald-600 from-sky-400">
                Wilgotność (Humidity):
              </span>
            </h2>
            <ul className="mb-5">
              {sensors.HUMIDITY.length === 0 ? (
                <p className="font-bold text-zinc-100 ">
                  Brak danych o wilgotności
                </p>
              ) : (
                sensors.HUMIDITY.map((item, index) => (
                  <Link to={`/chart?sensor=${item}&type=HUMIDITY`}>
                    <li
                      key={index}
                      className="font-bold text-zinc-100 hover:bg-slate-950 p-1"
                    >
                      {item}
                    </li>
                  </Link>
                ))
              )}
            </ul>
          </>
        )}

        {sensors.PRESSURE && (
          <>
            <h2 className="mb-2 text-1xl font-extrabold text-white md:text-1xl lg:text-1xl">
              <span class="text-transparent bg-clip-text bg-gradient-to-r to-emerald-600 from-sky-400">
                Ciśnienie (Pressure):
              </span>
            </h2>
            <ul className="mb-5">
              {sensors.PRESSURE.length === 0 ? (
                <p className="font-bold text-zinc-100">
                  Brak danych o ciśnieniu
                </p>
              ) : (
                sensors.PRESSURE.map((item, index) => (
                  <Link to={`/chart?sensor=${item}&type=PRESSURE`}>
                    <li
                      key={index}
                      className="font-bold text-zinc-100 hover:bg-slate-950 p-1"
                    >
                      {item}
                    </li>
                  </Link>
                ))
              )}
            </ul>
          </>
        )}
        {sensors.TEMPERATURE && (
          <>
            <h2 className="mb-2 text-1xl font-extrabold text-white md:text-1xl lg:text-1xl">
              <span className="text-transparent bg-clip-text bg-gradient-to-r to-emerald-600 from-sky-400">
                Temperatura (Temperature):
              </span>
            </h2>
            <ul className="mb-5">
              {sensors.TEMPERATURE.length === 0 ? (
                <p className="font-bold text-zinc-100">
                  Brak danych o temperaturze
                </p>
              ) : (
                sensors.TEMPERATURE.map((item, index) => (
                  <Link to={`/chart?sensor=${item}&type=TEMPERATURE`}>
                    <li
                      key={index}
                      className="font-bold text-zinc-100 hover:bg-slate-950 p-1"
                    >
                      {item}
                    </li>
                  </Link>
                ))
              )}
            </ul>
          </>
        )}
      </div>
    </div>
  );
};

export default LeftMenu;
