import { MapContainer, TileLayer, useMap, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { Icon } from "leaflet";
import { useState } from "react";
import { useEffect } from "react";
import URL from "../../config";
const customIcon = new Icon({
  iconUrl: require("../../placeholder.png"),
  iconSize: [38, 38], //wielkosc ikony
});

const SensorMap = () => {
  const [czujniki, setCzujniki] = useState(null); // Stan przechowujący dane o czujnikach

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${URL}sentilo/components`);
        if (!response.ok) {
          throw new Error("Wystąpił problem z pobraniem danych.");
        }
        const data = await response.json();
        setCzujniki(data); // Ustawienie danych w stanie
      } catch (error) {
        console.error("Błąd:", error);
      }
    };

    fetchData(); // Wywołanie funkcji pobierającej dane
  }, []); // Pusta tablica zależności oznacza, że useEffect zostanie wykonany tylko raz po pierwszym renderowaniu

  // Tworzenie markerów dla każdej grupy czujników
  const renderMarkers = () => {
    if (!czujniki) return null; // Jeśli dane nie zostały jeszcze pobrane, zwróć null

    const groupedSensors = {};
    czujniki.providers[0].sensors.forEach((sensor) => {
      if (!groupedSensors[sensor.component]) {
        groupedSensors[sensor.component] = [];
      }
      groupedSensors[sensor.component].push(sensor);
    });

    return Object.keys(groupedSensors).map((component) =>
      groupedSensors[component].map((sensor, index) => (
        <Marker
          key={`${component}-${index}`}
          position={[
            parseFloat(sensor.location.split(" ")[0]),
            parseFloat(sensor.location.split(" ")[1]),
          ]}
          icon={customIcon}
        >
          <Popup>
            <div>
              <h3>Nazwa komponentu: {sensor.component}</h3>
              <p>Typy czujników:</p>
              <ul>
                {groupedSensors[component].map((sensor, index) => (
                  <li key={index}>
                    Nazwa: {sensor.sensor}{" "}
                    {sensor.description && `- Opis: ${sensor.description}`}
                  </li>
                ))}
              </ul>
            </div>
          </Popup>
        </Marker>
      ))
    );
  };

  if (!czujniki) {
    // Jeśli dane nie zostały jeszcze pobrane, wyświetl mapę z określonym centrum
    return (
      <div className="h-[800px] sm:h-[500px] w-screen z-10">
        <MapContainer
          center={[49.719246, 21.711443]}
          zoom={17}
          scrollWheelZoom={false}
          className="w-full h-full"
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
        </MapContainer>
      </div>
    );
  }

  // Jeśli dane zostały pobrane, wyświetl markery na mapie zgodnie z funkcją renderMarkers
  return (
    <div className="h-[800px] sm:h-[500px] w-screen z-10">
      <MapContainer
        center={[49.719246, 21.711443]}
        zoom={17}
        scrollWheelZoom={false}
        className="w-full h-full"
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {/* Tworzenie markerów na podstawie pobranych danych */}
        {renderMarkers()} {/* Wywołujemy funkcję renderującą markery */}
      </MapContainer>
    </div>
  );
};

export default SensorMap;
