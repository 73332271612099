import React, { useEffect, useState } from "react";
import Chart from "chart.js/auto";
import { Line } from "react-chartjs-2";
import moment from "moment";
import { toast } from "react-toastify";
import URL from "../config";

const ChartLine = (props) => {
  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${URL}data/getChartDataByName?sensorType=${props.type}&sensorName=${props.sensor}&hours=${props.hour}`
        );
        if (!response.ok) {
          throw new Error("Coś poszło nie tak");
        }

        const chartData = await response.json();
        setChartData(chartData);
        if (
          Object.keys(chartData.valueMap).length === 0 &&
          props.sensor.length > 0
        ) {
          toast.warn("zbyt krótki okres czasu", {
            theme: "dark",
          });
        }
      } catch (error) {
        console.error("problem z pobraniem danych:", error);
        toast.error(`${error}`, {
          theme: "dark",
        });
      }
    };

    fetchData();
  }, [props.sensor, props.type, props.hour]);

  if (!chartData) {
    return <div>Wczytywanie...</div>;
  }
  const labels = chartData?.valueMap
    ? Object.keys(chartData.valueMap).map((date) =>
        moment(date).format("DD-MM-YYYY HH:mm")
      )
    : [];
  Chart.defaults.color = "#ffff";
  Chart.defaults.borderColor = "#505253";
  const data = {
    labels: labels,
    datasets: [
      {
        label: `${chartData.sensorName}`,
        backgroundColor: "rgb(10, 49, 71)",
        borderColor: "rgb(3, 255, 213)",

        data: Object.values(chartData.valueMap),
      },
    ],
  };

  return (
    <div className="md:w-3/4 sm:w-full">
      <h1 className="p-0 text-1xl font-extrabold text-white md:text-1xl lg:text-4xl mb-4 w-96 capitalize">
        {props.type}: {props.sensor}
      </h1>
      <Line data={data} />
    </div>
  );
};

export default ChartLine;
