import { useEffect, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import moment from "moment";
import pl from "date-fns/locale/pl";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import URL from "../config";

const Raport = () => {
  const [sensors, setSensors] = useState([]);
  const [selectedSensor, setSelectedSensor] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${URL}data/getSensors`);
        if (!response.ok) {
          throw new Error("Coś poszło nie tak");
        }

        const sensors = await response.json();
        setSensors(sensors);
      } catch (error) {
        console.error("błąd przy pobieraniu danych", error);
        toast.error(`${error}`, { theme: "dark" });
      }
    };

    fetchData();
  }, []);

  const handleSelectedSensor = (e) => {
    e.preventDefault(e);
    const type = e.target.value;
    setSelectedSensor(type);
  };

  const handleSendClick = async () => {
    const parsedStartDate = moment(
      startDate,
      "ddd MMM DD YYYY HH:mm:ss [GMT]Z"
    );
    const formattedStartDate = parsedStartDate.format("YYYY-MM-DDTHH:mm:ss");

    const parsedEndDate = moment(endDate, "ddd MMM DD YYYY HH:mm:ss [GMT]Z");
    const formattedEndDate = parsedEndDate.format("YYYY-MM-DDTHH:mm:ss");

    try {
      if (
        parsedStartDate != null &&
        formattedEndDate != null &&
        selectedSensor != null
      ) {
        const response = await fetch(
          `${URL}reports/dates?from=${formattedStartDate}&to=${formattedEndDate}&sensorType=${selectedSensor}`
        );
        if (response.status != 200) {
          throw new Error("wystąpił błąd z pobieraniem");
        }
        const blob = await response.blob();
        console.log(response);
        // Tworzenie linku do pobrania pliku
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${selectedSensor}.csv`);

        // Dodanie linku do DOM i kliknięcie w niego
        document.body.appendChild(link);
        link.click();

        // Usunięcie linku z DOM
        document.body.removeChild(link);
        setShowModal(false);
      } else {
        toast.warn("wybierz datę oraz sensor", {
          theme: "dark",
        });
      }
    } catch (error) {
      console.error("Błąd pobierania pliku:", error);
      toast.error(`${error}`, { theme: "dark" });
    }
  };
  registerLocale("pl", pl);

  return (
    <>
      <button
        className="bg-slate-900 text-white hover:bg-slate-950 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-5 mt-4 ease-linear transition-all duration-150 order-1 "
        type="button"
        onClick={() => setShowModal(true)}
      >
        wygeneruj raport
      </button>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none ">
            <div className="relative sm:w-screen xl:w-auto   ">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-slate-800">
                {/*header*/}
                <div className="flex items-start justify-between p-5  rounded-t">
                  <h3 className="text-3xl font-semibold">Raport</h3>
                </div>
                {/*body*/}
                <div className="flex items-start justify-between ml-5 ">
                  <h3 className="text-2xl font-semibold">
                    Wybierz zakres dat oraz typ sensora
                  </h3>
                </div>
                <div className="p-6 xl:flex sm:flex-row">
                  {/* datapicker */}

                  <DatePicker
                    locale={pl}
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    className="mr-5 h-fit lg:text-xl md:text-sm shadow appearance-none hover:bg-slate-950 rounded py-2 px-3 text-zinc-100 siez leading-tight focus:outline-none focus:shadow-outline bg-slate-900"
                  />
                  <DatePicker
                    locale={pl}
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    className="sm:mt-3 xl:mt-0 mr-5 h-fit lg:text-xl md:text-sm shadow appearance-none hover:bg-slate-950 rounded py-2 px-3 text-zinc-100 siez leading-tight focus:outline-none focus:shadow-outline bg-slate-900"
                  />

                  {sensors && (
                    <select
                      value={selectedSensor}
                      className="sm:mt-3 xl:mt-0 flex h-fit lg:text-xl md:text-sm shadow appearance-none hover:bg-slate-950 rounded py-2 px-3 text-zinc-100 siez leading-tight focus:outline-none focus:shadow-outline bg-slate-900"
                      onChange={handleSelectedSensor}
                    >
                      <option value="">Wybierz sensor</option>
                      {Object.keys(sensors).map((option, index) => (
                        <option key={index} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                  )}

                  {/* end of datapicker  */}
                </div>
                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                  <button
                    className="text-white hover:text-red-600 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-75"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    Zamknij
                  </button>
                  <button
                    className="text-white hover:text-emerald-500  font-bold uppercase text-sm px-6 py-3 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-75"
                    type="button"
                    onClick={handleSendClick}
                  >
                    Pobierz Raport
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};

export default Raport;
