import LeftMenu from "./LeftMenu";
import Dashboard from "./common/Dashboard";
import SensorsMap from "./common/SensorsMap";

const Main = () => {
  return (
    <div className="flex-wrap bg-slate-800 flex w-full text-zinc-100">
      <LeftMenu></LeftMenu>
      <Dashboard></Dashboard>
      <SensorsMap />
    </div>
  );
};
export default Main;
