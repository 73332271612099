import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Navbar from "./components/common/Navbar";
import Main from "./components/Main";
import Chart from "./components/Chart";
import Footer from "./components/common/Footer";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
<React.Fragment>
<Navbar />

<main className="w-full bg-slate-900">
  <Routes>

    <Route path="/Main" element={<Main />} />
    <Route path="/chart" element={<Chart />} />
    <Route path="/" element={<Navigate to="/Main" />} />
    <Route element={Main} />

  </Routes>
</main>
<Footer></Footer>

<ToastContainer />
</React.Fragment>
  );
}


export default App;