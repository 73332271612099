const WeatherComponent = (props) => {
  const date = new Date(props.timestamp);

  // Uzyskaj poszczególne komponenty daty
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();

  // Sformatuj datę do czytelnego formatu
  const formattedDate = `${day < 10 ? "0" : ""}${day}-${
    month < 10 ? "0" : ""
  }${month}-${year} ${hours}:${minutes < 10 ? "0" : ""}${minutes}:${
    seconds < 10 ? "0" : ""
  }${seconds}`;

  return (
    <div className="p-12 rounded shadow-lg bg-slate-900 w-fit h-fit mr-5 mt-4">
      <div className="mt-4  ">
        <h3 className="text-xl font-semibold mb-2">{props.type}</h3>
        <h3 className="text-xl font-semibold mb-2">{props.data}</h3>
        <h3 className="text-xl font-semibold mb-2">{formattedDate}</h3>
      </div>
    </div>
  );
};

export default WeatherComponent;
