import WeatherComponent from "./WeatherComponent";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import URL from "../../config";
import Raport from "../Raport";

const Dashboard = () => {
  const [latesData, setLatesData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${URL}data/getLiveData`);
        if (!response.ok) {
          throw new Error("coś poszło nie tak");
        }

        const latesData = await response.json();
        setLatesData(latesData);
      } catch (error) {
        console.error("problem z pobraniem danych", error);
        toast.error(`${error}`, {
          theme: "dark",
        });
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <div className="flex-wrap md:w-10/12 pl-6 pt-4 sm:w-full min-h-screen z-50">
        {latesData && (
          <>
            <h1 className="p-0 text-1xl font-extrabold text-white md:text-1xl lg:text-4xl mb-4 w-96">
              Ostatnie dane{" "}
            </h1>
            <div className="flex flex-wrap">
              {latesData.humidity && (
                <WeatherComponent
                  data={latesData.humidity + " %"}
                  type={"Wilgotność"}
                  timestamp={latesData.timestamp}
                />
              )}

              {latesData.temperature && (
                <WeatherComponent
                  data={latesData.temperature + " \u2103"}
                  type={"Temperatura"}
                  timestamp={latesData.timestamp}
                />
              )}
              {latesData.pressure && (
                <WeatherComponent
                  data={latesData.pressure + " hPa"}
                  type={"Ciśnienie"}
                  timestamp={latesData.timestamp}
                />
              )}
            </div>
            <Raport />
          </>
        )}

        {!latesData && (
          <h1 className="p-0 text-1xl font-extrabold text-white md:text-1xl lg:text-4xl mb-4 w-96">
            Brak danych do wyświetlenia{" "}
          </h1>
        )}
      </div>
    </>
  );
};

export default Dashboard;
